/* eslint-disable no-useless-escape */
import {Routes} from "@buildwithflux/core";
import {isProductionEnvironment} from "@buildwithflux/shared";
import CssBaseline from "@material-ui/core/CssBaseline";
import React, {Suspense, useCallback, useEffect, useMemo, useRef} from "react";
import {Redirect, Route, RouteComponentProps, Router, Switch} from "react-router-dom";
import createPersistedState from "use-persisted-state";

import Dialogs from "./components/common/components/dialog/Dialogs";
import {IntercomSetup} from "./components/common/components/intercom/IntercomSetup";
import {lazyRetry} from "./components/common/components/lazyLoader";
import FullPageLoader from "./components/common/components/loader/FullPageLoader";
import MobileInterstitial from "./components/common/components/MobileInterstitial";
import AppUpdateSnackbar from "./components/common/components/Snackbars/AppUpdate";
import {useFeatureFlags} from "./components/common/hooks/featureFlags/useFeatureFlag";
import {useFeatureFlagConnector} from "./components/common/hooks/featureFlags/useFeatureFlagConnector";
import {useFluxLogger} from "./components/common/hooks/useFluxLogger";
import {useNetlify} from "./components/common/hooks/useNetlify";
import history from "./components/helpers/history";
import useTheme from "./components/helpers/hooks/UseTheme";
import ScrollToTop from "./components/helpers/ScrollToTop";
import SetVersion from "./components/helpers/SetVersion";
import ConditionalRoute from "./components/pages/authentication/ConditionalRoute";
import {RouteWithErrorBoundary} from "./components/pages/authentication/RouteWithErrorBoundary";
import {currentAgentIsBot} from "./helpers/isBot";
import {isMobile} from "./helpers/platform";
import {useFluxServices} from "./injection/hooks";
import {blockUntilAuthReady} from "./modules/auth/hooks";
import {TrackingEvents} from "./modules/storage_engine/common/TrackingEvents";

const LogIn = lazyRetry(() => import(/* webpackChunkName: 'LogIn' */ "./components/pages/authentication/LogIn"));
const SignUp = lazyRetry(() => import(/* webpackChunkName: 'SignUp' */ "./components/pages/authentication/SignUp"));
const LogOut = lazyRetry(() => import(/* webpackChunkName: 'LogOut' */ "./components/pages/authentication/LogOut"));

const AccountUpgrade = lazyRetry(
    () => import(/* webpackChunkName: 'AccountUpgrade' */ "./components/pages/account/AccountUpgrade"),
);
const CreateOrganization = lazyRetry(
    () => import(/* webpackChunkName: 'CreateOrganization' */ "./components/pages/account/CreateOrganization"),
);
const ResetPassword = lazyRetry(
    () => import(/* webpackChunkName: 'ResetPassword' */ "./components/pages/authentication/ResetPassword"),
);
const Document = lazyRetry(() => import(/* webpackChunkName: 'Document' */ "./components/pages/document/Document"));
const Profile = lazyRetry(() => import(/* webpackChunkName: 'Profile' */ "./components/pages/profile/Profile"));
const CreateDocument = lazyRetry(
    () =>
        import(
            /* webpackChunkName: 'CreateDocument' */
            "./components/pages/document/components/create_document/CreateDocument"
        ),
);

const ForkDocument = lazyRetry(
    () =>
        import(
            /* webpackChunkName: 'ForkDocument' */
            "./components/pages/document/components/fork_document/ForkDocument"
        ),
);
const CloneDocument = lazyRetry(
    () =>
        import(
            /* webpackChunkName: 'CloneDocument' */
            "./components/pages/document/components/clone_document/CloneDocument"
        ),
);
const NotFound = lazyRetry(
    () => import(/* webpackChunkName: 'NotFound' */ "./components/pages/errors/404/PageNotFound"),
);
const LandingPage = lazyRetry(
    () => import(/* webpackChunkName: 'LandingPage' */ "./components/pages/landingpage/LandingPage"),
);
const Search = lazyRetry(() => import(/* webpackChunkName: 'Sitemap' */ "./components/pages/search/Search"));
const Sitemap = lazyRetry(() => import(/* webpackChunkName: 'Sitemap' */ "./components/pages/sitemap/Sitemap"));

const SitemapCategory = lazyRetry(
    () => import(/* webpackChunkName: 'SitemapCategory' */ "./components/pages/sitemap/pages/category/Category"),
);
const SitemapSubcategory = lazyRetry(
    () =>
        import(
            /* webpackChunkName: 'SitemapSubcategory' */ "./components/pages/sitemap/pages/sub_category/SubCategory"
        ),
);

if (isProductionEnvironment()) {
    // eslint-disable-next-line no-console
    console.info(`

         /$$$$$$$$ /$$
        | $$_____/| $$
       <  $$      | $$ /$$   /$$ /$$   /$$
        > $$$$$   | $$| $$  | $$|  $$ /$$/
       <  $$__/   | $$| $$  | $$ \  $$$$/
        > $$      | $$| $$  | $$  >$$  $$
       <  $$      | $$|  $$$$$$/ /$$/\  $$
        |__/      |__/ \______/ |__/  \__/


        We're hiring! (fully remote) https://coda.io/@flux-ai/flux-jobs
    `);
}

const loading = <FullPageLoader delay={450} showLongWaitExcuse={true} />;

const isLandingPage = () => {
    return window.location.pathname === Routes.LANDING;
};

const defaultRouteRender = ({staticContext}: RouteComponentProps<{[key: string]: string | undefined}>) => {
    if (staticContext) {
        staticContext.statusCode = 404;
    }
    return <NotFound />;
};

const getCloneDocumentForTemplates = (props: RouteComponentProps) => <CloneDocument {...props} mode={"template"} />;

const RESET_PASSWORD_ROUTES = [Routes.RESET_PASSWORD, Routes.RESET_PASSWORD_CALLBACK];
const PROFILE_ROUTES = [
    Routes.PROFILE,
    Routes.PROFILE_STARRED,
    Routes.PROFILE_MEMBERS,
    Routes.PROFILE_SETTINGS,
    Routes.PROFILE_BILLING,
    Routes.PROFILE_UPGRADE,
];

// Please keep your code out of here unless it truly need to run on ALL pages!
const AppComponent: React.FC = () => {
    // ATTENTION: the damn order of the code here matters!
    const {analyticsStorage} = useFluxServices();

    useFluxLogger();
    useFeatureFlagConnector();
    useNetlify();
    useTheme();

    const usePersistedState = useRef(createPersistedState("forceDesktop")).current;
    const [forceDesktop, setForceDesktop] = usePersistedState<boolean>(false);

    useEffect(() => {
        // initial page load
        analyticsStorage.logEvent(TrackingEvents.pageView);

        analyticsStorage.logEvent(TrackingEvents.appLoad, {
            app_load_time: Date.now() - window.performance.timing.navigationStart,
        });

        history.listen(() => {
            analyticsStorage.logEvent(TrackingEvents.pageView);
        });
    }, [analyticsStorage]);

    const isMobileInterstitialEnabled = useFeatureFlags((state) => state.enableMobileInterstitial || false);

    const showInterstitial = useMemo(() => {
        return isMobileInterstitialEnabled && isMobile() && !forceDesktop;
    }, [isMobileInterstitialEnabled, forceDesktop]);

    const redirectToCopilotDocs = useCallback(() => {
        window.location.href = "https://docs.flux.ai/reference/copilot";
        return null;
    }, []);

    return (
        <>
            <CssBaseline />
            <AppUpdateSnackbar useNotistack={false} />
            <SetVersion />
            <IntercomSetup />

            {showInterstitial && !currentAgentIsBot && !isLandingPage() && (
                <MobileInterstitial setForceDesktop={setForceDesktop} />
            )}

            {(!showInterstitial || isLandingPage()) && (
                <Router history={history}>
                    <ScrollToTop />
                    <Suspense fallback={loading}>
                        <Switch>
                            <Route path={Routes.COPILOT} exact render={redirectToCopilotDocs} />

                            <RouteWithErrorBoundary exact path={Routes.LANDING} component={LandingPage} />

                            <ConditionalRoute exact path={Routes.LOG_IN} isLogIn={true} component={LogIn} />
                            {/* It's important that all users can access the log out action, not just fully authenticated ones */}
                            <RouteWithErrorBoundary exact path={Routes.LOG_OUT} component={LogOut} />

                            {/* These are legacy routes to keep /signin and /signout working */}
                            <Route exact path={Routes.SIGN_IN}>
                                <Redirect to={Routes.LOG_IN} />
                            </Route>
                            <Route exact path={Routes.SIGN_OUT}>
                                <Redirect to={Routes.LOG_OUT} />
                            </Route>

                            <ConditionalRoute isSignUp={true} exact path={Routes.SIGN_UP} component={SignUp} />

                            <RouteWithErrorBoundary exact path={Routes.ACCOUNT_UPGRADE} component={AccountUpgrade} />
                            <RouteWithErrorBoundary
                                exact
                                path={Routes.CREATE_ORGANIZATION}
                                component={CreateOrganization}
                            />

                            <RouteWithErrorBoundary exact path={Routes.SEARCH} component={Search} />
                            <RouteWithErrorBoundary exact path={Routes.SITEMAP} component={Sitemap} />
                            <RouteWithErrorBoundary exact path={Routes.SITEMAP_CATEGORY} component={SitemapCategory} />
                            <RouteWithErrorBoundary
                                exact
                                path={Routes.SITEMAP_SUBCATEGORY}
                                component={SitemapSubcategory}
                            />

                            <RouteWithErrorBoundary exact path={RESET_PASSWORD_ROUTES} component={ResetPassword} />
                            <ConditionalRoute
                                isProtected={true}
                                exact
                                path={Routes.CREATE_DOCUMENT}
                                component={CreateDocument}
                            />
                            <ConditionalRoute
                                isProtected={true}
                                exact
                                path={Routes.CREATE_DOCUMENT_FROM_TEMPLATE}
                                render={getCloneDocumentForTemplates}
                            />
                            <RouteWithErrorBoundary exact path={Routes.FORK_DOCUMENT} component={ForkDocument} />
                            <ConditionalRoute
                                isProtected={true}
                                exact
                                path={Routes.CLONE_DOCUMENT}
                                component={CloneDocument}
                            />
                            <ConditionalRoute exact path={Routes.DOCUMENTS} component={Document} />
                            <ConditionalRoute exact path={PROFILE_ROUTES} component={Profile} />
                            <ConditionalRoute exact path={Routes.DOCUMENT} component={Document} />

                            <RouteWithErrorBoundary exact path="*" render={defaultRouteRender} component={NotFound} />
                        </Switch>
                        <Dialogs />
                    </Suspense>
                </Router>
            )}
        </>
    );
};

export default blockUntilAuthReady(AppComponent);
